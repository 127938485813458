
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { Form, Field } from "vee-validate";
import { object, string, array, number } from "yup";
import draggable from "vuedraggable";
import Quill from "quill/dist/quill.js";
import eventsApi from "@/core/services/EventsApi";
import axios from "axios";

export default defineComponent({
    name: "builder",
    components: {
        Form,
        Field,
        draggable,
    },
    data() {
        return {
            loading: true,
            editing: false,
            editingHappening: false,
            editingLocations: false,
            editingAccommodations: false,
            editingSpeakers: false,
            previewImgDialog: false,
            editingRooms: false,
            intoInfos: false,
            intoPayments: false,
            intoHappenings: false,
            intoLocations: false,
            intoAccommodations: false,
            intoSpeakers: false,
            dialogImageUrl: "",
            event: {} as any,
            competitions: [] as any,
            editions: [] as any,
            roles: [] as any,
            payment_options: [] as any,
            currencies: [] as any,
            bed_sizes: [] as any,
            happenings: [] as any,
            locations: [],
            accommodations: [],
            speakers: [],
            rooms: [],
            newEvent: {
                main_infos: {
                    title: "",
                    subtitle: "",
                    desc: "",
                    location: "",
                    calendar: {
                        event_dates: "" as any,
                        registration_dates: "" as any,
                    },
                    contact: {
                        address: "",
                        name: "",
                        email: "",
                        phone: "",
                    },
                    comp: {
                        competition: "",
                        edition: "",
                        role: "",
                    },
                    gallery: {
                        files: [] as any,
                        thumbnail: "",
                    },
                    more_files: [] as any,
                },
                payments: {
                    management_fee: {
                        fee: "",
                        currency: "",
                        no_fee_for_ca: "",
                        pay_for_rooms: "",
                        fee_per_person: "",
                        options: [] as any,
                    },
                    details: {
                        general: "",
                        wire: "",
                        cheque: "",
                        paypal: "",
                    },
                },
            },
            newHappening: {
                title: "",
                subtitle: "",
                desc: "",
                date: "",
                time: [],
                main_image: "" as any,
                price: "",
                management_fee: "",
                max_members: "",
                fully_booked: false,
            } as any,
            newLocation: {
                title: "",
                subtitle: "",
                desc: "",
                images: [],
            } as any,
            newAccommodation: {
                title: "",
                subtitle: "",
                desc: "",
                images: [],
            } as any,
            newSpeaker: {
                title: "",
                position: "",
                desc: "",
                images: [],
            } as any,
            newRoom: {
                title: "",
                desc: "",
                rooms_nb: "",
                occupancy: "",
                bed_sizes: [],
                price: "",
                dates: [],
                main_image: "",
                images: [],
            } as any,
            perm_pos: "",
            happeningsTh: [
                { label: "Date & Time", class: "min-w-60px", colName: "date_time" },
                { label: "Title", class: "min-w-60px", colName: "title" },
                { label: "Price", class: "min-w-100px", colName: "price" },
                { label: "Max members", class: "min-w-60px", colName: "max_members" },
            ],
            editor_main_infos: "" as any,
            editor_payment_1: "" as any,
            editor_payment_2: "" as any,
            editor_payment_3: "" as any,
            activeAccommodationId: "",
            actualCol: "",
            actualFilter: "",
            currentPage: 1,
            happeningDialog: false,
            locationDialog: false,
            accommodationDialog: false,
            speakerDialog: false,
            roomsDialog: false,
            title: "" as any,
            displayPaypal: false,
            finalGalleryMainInfos: [] as any,
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const resultsPerPage = 10;

        const editor = "wysiwyg";
        const editorPayment1 = "wysiwygPayment1";
        const editorPayment2 = "wysiwygPayment2";
        const editorPayment3 = "wysiwygPayment3";

        const editorOptions = {
            modules: {
                toolbar: {
                    container: "#toolbar",
                },
            },
            theme: "snow",
        };
        const editorOptionsPayment1 = {
            modules: {
                toolbar: {
                    container: "#toolbarPayment1",
                },
            },
            theme: "snow",
        };
        const editorOptionsPayment2 = {
            modules: {
                toolbar: {
                    container: "#toolbarPayment2",
                },
            },
            theme: "snow",
        };
        const editorOptionsPayment3 = {
            modules: {
                toolbar: {
                    container: "#toolbarPayment3",
                },
            },
            theme: "snow",
        };

        const eventSchema = object().shape({
            title: string().required().label("Event title").nullable(),
            subtitle: string().required().label("Event subtitle").nullable(),
            location: string().required().label("Event location").nullable(),
            event_dates: array().required().label("Event dates").nullable(),
            registration_dates: array().required().label("Registration dates").nullable(),
            address: string().required().label("Address").nullable(),
            name: string().required().label("Name").nullable(),
            email: string().email().required().label("Email address").nullable(),
            phone: string().required().label("Phone number").nullable(),
            competition: string().required().label("Competition").nullable(),
            role: string().required().label("Role address").nullable(),
            edition: string().required().label("Edition").nullable(),
            fixed_fee: number().positive("Fixed fee must be positive").required().typeError("Please input a number").nullable(),
            currency: string().required().label("Currency").nullable(),
            no_fee_for_ca: string().required().label("Fees for CA members").nullable(),
            fee_per_person: string().required().label("Fee per person").nullable(),
            pay_for_rooms: string().required().label("Pay for rooms").nullable(),
            payment_options: array().min(1).label("Payment options").nullable(),
        });

        const happeningSchema = object().shape({
            title: string().required().label("Title").nullable(),
            subtitle: string().required().label("Subtitle").nullable(),
            desc: string().required().label("Description").nullable(),
            date: string().required().label("Date").nullable(),
            time: array().min(1, "Time is a required field").label("Time").nullable(),
            price: number().positive("Price must be positive").required().typeError("Please input a number").nullable(),
            management_fee: string().required().label("Management fee").nullable(),
            max_members: number().positive("Max members number must be positive").required().typeError("Please input a number").nullable(),
        });

        const locationSchema = object().shape({
            title: string().required().label("Title").nullable(),
            subtitle: string().required().label("Subtitle").nullable(),
            desc: string().required().label("Description").nullable(),
        });
        const accommodationSchema = object().shape({
            title: string().required().label("Title").nullable(),
            subtitle: string().required().label("Subtitle").nullable(),
            desc: string().required().label("Description").nullable(),
        });
        const speakerSchema = object().shape({
            title: string().required().label("Title").nullable(),
            position: string().required().label("Position").nullable(),
            desc: string().required().label("Description").nullable(),
        });
        const roomSchema = object().shape({
            title: string().required().label("Description").nullable(),
            desc: string().required().label("Position").nullable(),
            rooms_nb: number().positive("Number must be positive").required().typeError("Please input a number").nullable(),
            occupancy: number().positive("Number must be positive").required().typeError("Please input a number").nullable(),
            bed_sizes: array().min(1, "Please select at least one bed size").label("Bed Size").nullable(),
            price: number().positive("Price must be positive").required().typeError("Please input a number").nullable(),
            dates: array().min(1, "Starting & ending dates are a required").label("Dates").nullable(),
        });

        return {
            router,
            route,
            eventSchema,
            happeningSchema,
            locationSchema,
            accommodationSchema,
            speakerSchema,
            roomSchema,
            editor,
            editorOptions,
            editorPayment1,
            editorOptionsPayment1,
            editorPayment2,
            editorOptionsPayment2,
            editorPayment3,
            editorOptionsPayment3,
            resultsPerPage,
        };
    },
    mounted() {
        if (this.route.params.event_id) {
            this.editing = true;
            this.getEvent(this.route.params.event_id);
            setCurrentPageBreadcrumbs("Event #" + this.route.params.event_id, [
                {
                    label: "Events list",
                    link: "/events",
                },
            ]);
        } else {
            setCurrentPageBreadcrumbs("New event", [
                {
                    label: "Events list",
                    link: "/events",
                },
            ]);
        }
        this.title = document.querySelector("#title-breadcrumbs h1") as HTMLElement;
        this.title.style.cssText = "display: none!important";

        this.getFields();

        this.intoInfos = true;

        this.editor_main_infos = new Quill("#" + this.editor, this.editorOptions);
        this.editor_payment_1 = new Quill("#" + this.editorPayment1, this.editorOptionsPayment1);
        this.editor_payment_2 = new Quill("#" + this.editorPayment2, this.editorOptionsPayment2);
        this.editor_payment_3 = new Quill("#" + this.editorPayment3, this.editorOptionsPayment3);

        this.editor_main_infos.on("text-change", () => {
            var delta = this.editor_main_infos.root.innerHTML;
            this.newEvent.main_infos.desc = delta;
        });

        this.editor_payment_1.on("text-change", () => {
            var delta = this.editor_payment_1.root.innerHTML;
            this.newEvent.payments.details.general = delta;
        });

        this.editor_payment_2.on("text-change", () => {
            var delta = this.editor_payment_2.root.innerHTML;
            this.newEvent.payments.details.wire = delta;
        });

        this.editor_payment_3.on("text-change", () => {
            var delta = this.editor_payment_3.root.innerHTML;
            this.newEvent.payments.details.cheque = delta;
        });
    },
    methods: {
        getFields() {
            eventsApi.listOptions().then((res) => {
                this.payment_options = res.data;
            });
            eventsApi.listCurrencies().then((res) => {
                this.currencies = res.data;
            });
            eventsApi.listBedSizes().then((res) => {
                this.bed_sizes = res.data;
            });
            eventsApi.listCompetitons().then((res) => {
                this.competitions = res.data;
            });
            eventsApi.listEditions().then((res) => {
                this.editions = res.data;
            });
            eventsApi.listRoles().then((res) => {
                this.roles = res.data;
                this.loading = false;
            });
        },
        checkPaypalField(e: any) {
            let paypalId;

            this.payment_options.map((option: any) => {
                if (option.label === "Paypal") {
                    paypalId = option.id;

                    if (Array.isArray(e)) {
                        if (e.includes(paypalId)) this.displayPaypal = true;
                        else this.displayPaypal = false;
                    } else {
                        if (e === paypalId) {
                            this.displayPaypal = false;
                            this.newEvent.payments.details.paypal = "";
                        }
                    }
                }
            });
        },
        getEvent(id: any) {
            eventsApi.getSpecificEvent(id).then((res: any) => {
                let formattedOptions = [] as any;

                res.data.options.map((option: any) => {
                    formattedOptions.push(option.id);
                    if (option.label === "Paypal") this.displayPaypal = true;
                });

                this.newEvent = {
                    main_infos: {
                        title: res.data.title,
                        subtitle: res.data.subtitle,
                        desc: res.data.description,
                        location: res.data.location,
                        calendar: {
                            event_dates: [res.data.start_date, res.data.end_date],
                            registration_dates: [res.data.start_reservation_date, res.data.end_reservation_date],
                        },
                        contact: {
                            address: res.data.contact_address,
                            name: res.data.contact_name,
                            email: res.data.contact_email,
                            phone: res.data.contact_phone,
                        },
                        comp: {
                            competition: res.data.competition,
                            edition: res.data.edition,
                            role: res.data.role,
                        },
                        gallery: {
                            files: [] as any,
                            thumbnail: "",
                        },
                        more_files: [],
                    },
                    payments: {
                        management_fee: {
                            fee: res.data.fee,
                            currency: res.data.currency,
                            no_fee_for_ca: res.data.is_ca_member_no_fees,
                            pay_for_rooms: res.data.is_paid_per_room,
                            fee_per_person: res.data.is_fee_per_person,
                            options: formattedOptions,
                        },
                        details: {
                            general: res.data.detail_option,
                            wire: res.data.detail_bank,
                            cheque: res.data.detail_cheque,
                            paypal: res.data.paypal_info,
                        },
                    },
                };

                this.editor_main_infos.container.firstChild.innerHTML = res.data.description;
                this.editor_payment_1.container.firstChild.innerHTML = res.data.detail_option;
                this.editor_payment_2.container.firstChild.innerHTML = res.data.detail_bank;
                this.editor_payment_3.container.firstChild.innerHTML = res.data.detail_cheque;
            });
            this.listHappenings();
            this.listLocations();
            this.listAccommodations();
            this.listSpeakers();
            this.getMainInfosGallery();
        },
        listHappenings() {
            this.loading = true;
            eventsApi.listHappenings({ p: this.currentPage, presult: this.resultsPerPage, event: this.route.params.event_id }).then((res: any) => {
                this.happenings = res.data.object_list;
                this.loading = false;
            });
        },
        listAccommodations() {
            this.loading = true;
            eventsApi.listAccommodations({ p: 1, presult: 9999, event: this.route.params.event_id }).then((res: any) => {
                this.accommodations = res.data.object_list;
                this.loading = false;
            });
        },
        listSpeakers() {
            this.loading = true;
            eventsApi.listSpeakers({ p: 1, presult: 9999, event: this.route.params.event_id }).then((res: any) => {
                this.speakers = res.data.object_list;
                this.loading = false;
            });
        },
        listRooms() {
            this.loading = true;
            eventsApi.listRooms({ p: 1, presult: 9999, accommodation: this.activeAccommodationId }).then((res: any) => {
                this.rooms = res.data.object_list;
                this.loading = false;
            });
        },
        confirmBackToList() {
            if (!this.editing) {
                ElMessageBox.confirm("You are about to leave this page. Any change will be lost. Confirm?", "Confirmation", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                    dangerouslyUseHTMLString: true,
                })
                    .then(() => {
                        this.router.back();
                    })
                    .catch(() => {
                        return; // Cancel
                    });
            } else {
                this.router.back();
            }
        },
        onEnd() {
            ElMessage({
                message: "Order saved!",
                type: "success",
                duration: 1500,
            });
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    this.newHappening.main_image = ev.target.result;
                }
            };
            if (selectedImage) reader.readAsDataURL(selectedImage);
        },
        setHeader(tab: string) {
            switch (tab) {
                case "infos":
                    this.intoInfos = true;
                    this.intoPayments = this.intoHappenings = this.intoLocations = this.intoAccommodations = this.intoSpeakers = false;
                    break;
                case "payments":
                    this.intoPayments = true;
                    this.intoInfos = this.intoHappenings = this.intoLocations = this.intoAccommodations = this.intoSpeakers = false;
                    break;
                case "happenings":
                    this.intoHappenings = true;
                    this.intoPayments = this.intoInfos = this.intoLocations = this.intoAccommodations = this.intoSpeakers = false;
                    break;
                case "locations":
                    this.intoLocations = true;
                    this.intoPayments = this.intoHappenings = this.intoInfos = this.intoAccommodations = this.intoSpeakers = false;
                    break;
                case "accommodations":
                    this.intoAccommodations = true;
                    this.intoPayments = this.intoHappenings = this.intoLocations = this.intoInfos = this.intoSpeakers = false;
                    break;
                case "speakers":
                    this.intoSpeakers = true;
                    this.intoPayments = this.intoHappenings = this.intoLocations = this.intoAccommodations = this.intoInfos = false;
                    break;
            }
        },
        checkForm(values) {
            this.eventSchema.isValid(values).then((valid) => {
                if (!valid) {
                    ElMessage({
                        message: "Please fill all required fields in Main Informations & Payments tabs",
                        type: "error",
                    });
                }
            });
        },

        // MAIN INFOS GALLERY
        getMainInfosGallery() {
            eventsApi.getGallery({ path: "event/" + this.route.params.event_id + "/images/" }).then((res: any) => {
                res.data.map((file: any) => {
                    this.newEvent.main_infos.gallery.files.push({
                        url: file.path,
                        is_main: file.is_main === "false" ? false : true,
                    });
                    this.newEvent.main_infos.gallery.files.sort((a: any, b: any) => (a.is_main < b.is_main ? 1 : -1));
                });
            });
        },
        handleRequest(e: any) {
            this.newEvent.main_infos.gallery.files.map((localFile: any) => {
                if (localFile.is_main && e.file.uid === localFile.uid) e.file.is_main = true;
                else if (e.file.uid === localFile.uid) e.file.is_main = false;
            });

            this.finalGalleryMainInfos.push(e.file);
        },
        setFileList(file: any, fileList: any) {
            let hasMainImage = false;

            this.newEvent.main_infos.gallery.files = fileList;
            this.newEvent.main_infos.gallery.files.map((file: any) => {
                if (file && file.is_main) hasMainImage = true;
            });

            if (this.newEvent.main_infos.gallery.files.length > 0) {
                if (!hasMainImage) {
                    this.newEvent.main_infos.gallery.files[0].is_main = true;
                } else {
                    for (let i = 0; i < this.newEvent.main_infos.gallery.files.length; i++) {
                        if (this.newEvent.main_infos.gallery.files[i].is_main !== true) this.newEvent.main_infos.gallery.files[i].is_main = false;
                    }
                }
            }
        },
        handleChange(file, fileList) {
            for (var i = 0; i <= fileList.length - 2; i++) {
                if (fileList[i].status === "success") {
                    var fileName = fileList[i].url.split("/");
                    fileName = fileName[fileName.length - 1];
                    if (fileName === file.name) {
                        ElMessage({
                            message: "File already exists",
                            type: "error",
                        });
                        (this.$refs.upload as any).handleRemove(file);
                    }
                } else if (fileList[i].status === "ready" && fileList[i].name === file.name) {
                    ElMessage({
                        message: "File already exists",
                        type: "error",
                    });
                    (this.$refs.upload as any).handleRemove(file);
                } else {
                    this.setFileList(file, fileList);
                }
            }
        },
        handleRemove(uploadFile) {
            if (!this.editing) {
                if (
                    uploadFile.raw.uid === this.newEvent.main_infos.gallery.files[0].raw.uid &&
                    uploadFile.is_main &&
                    this.newEvent.main_infos.gallery.files.length > 1
                ) {
                    this.newEvent.main_infos.gallery.files[1].is_main = true;
                    (this.$refs.upload as any).handleRemove(uploadFile);
                    this.setFileList(null, Array.from((this.$refs.upload as any).uploadFiles));
                } else if (this.newEvent.main_infos.gallery.files.length <= 1) {
                    ElMessage({
                        message: "You cannot delete the main image.",
                        type: "error",
                    });
                } else {
                    (this.$refs.upload as any).handleRemove(uploadFile);
                    this.setFileList(null, Array.from((this.$refs.upload as any).uploadFiles));
                }
            } else {
                if (
                    uploadFile.url === this.newEvent.main_infos.gallery.files[0].url &&
                    uploadFile.is_main &&
                    this.newEvent.main_infos.gallery.files.length > 1
                ) {
                    this.newEvent.main_infos.gallery.files[1].is_main = true;
                    (this.$refs.upload as any).handleRemove(uploadFile);
                    this.setFileList(null, Array.from((this.$refs.upload as any).uploadFiles));
                    this.deleteImage(uploadFile);
                } else if (this.newEvent.main_infos.gallery.files.length <= 1) {
                    ElMessage({
                        message: "You cannot delete the main image.",
                        type: "error",
                    });
                } else {
                    (this.$refs.upload as any).handleRemove(uploadFile);
                    this.setFileList(null, Array.from((this.$refs.upload as any).uploadFiles));
                    this.deleteImage(uploadFile);
                }
            }
        },
        deleteImage(uploadFile: any) {
            const words = uploadFile.url.split("/");
            var finalStr = "";

            words.map((word, index) => {
                if (index >= 4) finalStr += word + "/";
            });

            finalStr = finalStr.slice(0, -1);

            eventsApi.deleteImage({ path: finalStr }).then(() => {
                ElMessage({
                    message: "Image deleted",
                    type: "success",
                });
            });
        },
        handlePictureCardPreview(uploadFile) {
            this.dialogImageUrl = uploadFile.url;
            this.previewImgDialog = true;
        },
        handleMainImage(uploadFile) {
            if (!this.editing) {
                this.newEvent.main_infos.gallery.files.map((file: any) => {
                    console.log(file, uploadFile);

                    if (file.raw.uid === uploadFile.raw.uid) file.is_main = true;
                    else file.is_main = false;
                });
            } else {
                this.setFileList(null, Array.from((this.$refs.upload as any).uploadFiles));
                this.newEvent.main_infos.gallery.files.map((file: any) => {
                    if (file.url === uploadFile.url) file.is_main = true;
                    else file.is_main = false;
                });
            }
            ElMessage({
                message: "Main image updated",
                type: "success",
            });
        },
        // END MAIN INFOS GALLERY

        addEvent(e: any) {
            if (this.newEvent.main_infos.desc === "") {
                ElMessage({
                    message: "Please fill the description field.",
                    type: "error",
                });
            } else if (this.newEvent.main_infos.gallery.files.length === 0) {
                ElMessage({
                    message: "Please upload at least one image.",
                    type: "error",
                });
            } else if (this.newEvent.payments.management_fee.options.includes("PayPal account") && this.newEvent.payments.details.paypal === "") {
                ElMessage({
                    message: "Please specify a PayPal account",
                    type: "error",
                });
            } else {
                var payload = {
                    title: e.title,
                    subtitle: e.subtitle,
                    description: this.newEvent.main_infos.desc,
                    location: e.location,

                    start_date: e.event_dates[0],
                    end_date: e.event_dates[1],
                    start_reservation_date: e.registration_dates[0],
                    end_reservation_date: e.registration_dates[1],

                    competition: this.newEvent.main_infos.comp.competition,
                    edition: this.newEvent.main_infos.comp.edition,
                    role: this.newEvent.main_infos.comp.role,

                    contact_address: e.address,
                    contact_name: e.name,
                    contact_email: e.email,
                    contact_phone: e.phone,

                    fee: parseInt(e.fixed_fee),
                    currency: e.currency,
                    options: e.payment_options,
                    paypal_info: this.newEvent.payments.details.paypal,
                    is_ca_member_no_fees: e.no_fee_for_ca,
                    is_fee_per_person: e.fee_per_person,
                    is_paid_per_room: e.pay_for_rooms,
                    detail_option: this.newEvent.payments.details.general,
                    detail_bank: this.newEvent.payments.details.wire,
                    detail_cheque: this.newEvent.payments.details.cheque,
                };

                if (this.editing) {
                    eventsApi.updateEvent(this.route.params.event_id, payload).then((response: any) => {
                        if (response.success) {
                            this.newEvent.main_infos.gallery.files.map((file: any, index: number) => {
                                let formData = new FormData();
                                const words = file.url.split("/");
                                var finalStr = "";

                                words.map((word, index) => {
                                    if (index >= 4) finalStr += word + "/";
                                });

                                finalStr = finalStr.slice(0, -1);
                                formData.append("file", file.raw);
                                formData.append("path", finalStr ? finalStr : "event/" + this.route.params.event_id + "/images/");
                                formData.append("is_main", file.is_main ? "true" : "false");

                                axios.post("/api/v1/event/update-file/", formData, {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                });

                                if (index === this.newEvent.main_infos.gallery.files.length - 1) {
                                    ElMessage({
                                        message: "Successfully updated event!",
                                        type: "success",
                                    });
                                    this.$router.push("/events");
                                }
                            });
                        } else {
                            ElMessage({
                                message: "An error occured while updating the event.",
                                type: "error",
                            });
                        }
                    });
                } else {
                    eventsApi.addEvent(payload).then((res: any) => {
                        if (res.success) {
                            this.newEvent.main_infos.gallery.files.map((file: any, index: number) => {
                                let formData = new FormData();
                                formData.append("file", file.raw);
                                formData.append("is_main", file.is_main);
                                formData.append("path", "event/" + res.data.id + "/images/");
                                axios.post("/api/v1/event/upload-file/", formData, {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                });

                                if (index === this.newEvent.main_infos.gallery.files.length - 1) {
                                    ElMessage({
                                        message: "Successfully added event!",
                                        type: "success",
                                    });
                                    this.$router.push("/events");
                                }
                            });
                        } else {
                            ElMessage({
                                message: "An error occured while adding the event.",
                                type: "error",
                            });
                        }
                    });
                }
            }
        },
        editHappening(happening?: any) {
            if (happening) {
                this.editingHappening = true;
                eventsApi.getSpecificHappening(happening.id).then((res: any) => {
                    this.newHappening = {
                        title: res.data.title,
                        subtitle: res.data.subtitle,
                        desc: res.data.description,
                        date: happening.date,
                        time: [res.data.start_time, res.data.end_time],
                        main_image: "",
                        price: res.data.price,
                        management_fee: res.data.is_management_fee_applied,
                        max_members: res.data.max_members,
                        fully_booked: res.data.is_full,
                    };
                });
            } else {
                this.editingHappening = false;
            }
            this.happeningDialog = true;
        },
        addHappening(e: any) {
            var payload = {
                event: parseInt(this.route.params.event_id as any),
                title: e.title,
                subtitle: e.subtitle,
                description: e.desc,
                date: e.date,
                start_time: e.time[0],
                end_time: e.time[1],
                price: parseInt(e.price),
                is_management_fee_applied: e.management_fee,
                max_members: parseInt(e.max_members),
                is_full: this.newHappening.fully_booked,
            };
            eventsApi.addHappening(payload).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully added happening!",
                        type: "success",
                    });
                    this.happeningDialog = false;
                    this.listHappenings();
                } else {
                    ElMessage({
                        message: "An error occured while adding the happening.",
                        type: "error",
                    });
                }
            });
        },
        deleteHappening(id) {
            eventsApi.deleteHappening(id).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully deleted happening!",
                        type: "success",
                    });
                    this.listHappenings();
                } else {
                    ElMessage({
                        message: "An error occured while deleting the happening.",
                        type: "error",
                    });
                }
            });
        },
        editLocation(location?: any) {
            if (location) {
                this.editingLocations = true;
                eventsApi.getSpecificLocation(location.id).then((res: any) => {
                    this.newLocation = {
                        title: res.data.title,
                        subtitle: res.data.subtitle,
                        desc: res.data.description,
                    };
                });
            } else {
                this.editingLocations = false;
            }
            this.locationDialog = true;
        },
        listLocations() {
            this.loading = true;
            eventsApi.listLocations({ p: 1, presult: 9999, event: this.route.params.event_id }).then((res: any) => {
                this.locations = res.data.object_list;
                this.loading = false;
            });
        },
        addLocation(e: any) {
            var payload = {
                event: parseInt(this.route.params.event_id as any),
                title: e.title,
                subtitle: e.subtitle,
                description: e.desc,
            };
            eventsApi.addLocation(payload).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully added location!",
                        type: "success",
                    });
                    this.locationDialog = false;
                    this.listLocations();
                } else {
                    ElMessage({
                        message: "An error occured while adding the location.",
                        type: "error",
                    });
                }
            });
        },
        deleteLocation(id) {
            eventsApi.deleteLocation(id).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully deleted location!",
                        type: "success",
                    });
                    this.listLocations();
                } else {
                    ElMessage({
                        message: "An error occured while deleting the location.",
                        type: "error",
                    });
                }
            });
        },
        editAccommodation(accommodation?: any) {
            if (accommodation) {
                this.editingAccommodations = true;
                eventsApi.getSpecificAccommodation(accommodation.id).then((res: any) => {
                    this.newAccommodation = {
                        title: res.data.title,
                        subtitle: res.data.subtitle,
                        desc: res.data.description,
                    };
                    eventsApi.listRooms({ p: 1, presult: 9999, accommodation: accommodation.id }).then((res: any) => {
                        this.rooms = res.data.object_list;
                    });
                });
            } else {
                this.editingAccommodations = false;
            }
            this.accommodationDialog = true;
        },
        addAccommodation(e: any) {
            var payload = {
                event: parseInt(this.route.params.event_id as any),
                title: e.title,
                subtitle: e.subtitle,
                description: e.desc,
            };
            eventsApi.addAccommodation(payload).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully added accommodation!",
                        type: "success",
                    });
                    this.accommodationDialog = false;
                    this.listAccommodations();
                } else {
                    ElMessage({
                        message: "An error occured while adding the accommodation.",
                        type: "error",
                    });
                }
            });
        },
        deleteAccommodation(id) {
            eventsApi.deleteAccommodation(id).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully deleted accommodation!",
                        type: "success",
                    });
                    this.listAccommodations();
                } else {
                    ElMessage({
                        message: "An error occured while deleting the accommodation.",
                        type: "error",
                    });
                }
            });
        },
        editSpeaker(speaker?: any) {
            if (speaker) {
                this.editingSpeakers = true;
                eventsApi.getSpecificSpeaker(speaker.id).then((res: any) => {
                    this.newSpeaker = {
                        title: res.data.title,
                        position: res.data.position,
                        desc: res.data.description,
                    };
                });
            } else {
                this.editingSpeakers = false;
            }
            this.speakerDialog = true;
        },
        addRoom(e: any) {
            var payload = {
                accommodation: this.activeAccommodationId,
                title: e.title,
                description: e.desc,
                room_number: e.rooms_nb,
                room_occupancy: e.occupancy,
                bed_sizes: e.bed_sizes,
                price: e.price,
                start_date: e.dates[0],
                end_date: e.dates[1],
            };
            eventsApi.addRoom(payload).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully added room!",
                        type: "success",
                    });
                    this.roomsDialog = false;
                    this.listRooms();
                } else {
                    ElMessage({
                        message: "An error occured while adding the room.",
                        type: "error",
                    });
                }
            });
        },
        editRoom(room?: any) {
            if (room) {
                this.editingRooms = true;
                eventsApi.getSpecificRoom(room.id).then((res: any) => {
                    this.newRoom = {
                        title: res.data.title,
                        desc: res.data.description,
                        rooms_nb: res.data.room_number,
                        occupancy: res.data.room_occupancy,
                        bed_sizes: res.data.bed_sizes,
                        price: res.data.price,
                        dates: [res.data.start_date, res.data.end_date],
                    };
                });
            } else {
                this.editingRooms = false;
            }
            this.roomsDialog = true;
        },
        deleteRoom(id) {
            eventsApi.deleteRoom(id).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully deleted room!",
                        type: "success",
                    });
                    this.listRooms();
                } else {
                    ElMessage({
                        message: "An error occured while deleting the room.",
                        type: "error",
                    });
                }
            });
        },
        addSpeaker(e: any) {
            var payload = {
                event: parseInt(this.route.params.event_id as any),
                title: e.title,
                position: e.position,
                description: e.desc,
            };
            eventsApi.addSpeaker(payload).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully added speaker!",
                        type: "success",
                    });
                    this.speakerDialog = false;
                    this.listSpeakers();
                } else {
                    ElMessage({
                        message: "An error occured while adding the speaker.",
                        type: "error",
                    });
                }
            });
        },
        deleteSpeaker(id) {
            eventsApi.deleteSpeaker(id).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        message: "Successfully deleted speaker!",
                        type: "success",
                    });
                    this.listSpeakers();
                } else {
                    ElMessage({
                        message: "An error occured while deleting the speaker.",
                        type: "error",
                    });
                }
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            // this.getBillers();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            // this.selectedBillers = [];
            // var topCheckbox = document.getElementById("topCheckbox") as any;
            // topCheckbox.checked = false;

            this.currentPage = pageNumber;
            // this.getBillers();
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
});
