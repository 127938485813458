import ApiService from "./ApiService";

const UPLOAD_GALLERY = "api/v1/event/upload-file/"
const GET_GALLERY = "api/v1/event/get-files/"
const DELETE_IMAGE = "api/v1/event/delete-file/"

const ADD_SPEAKER = "api/v1/speaker/"
const GET_SPECIFIC_SPEAKER = "api/v1/speaker/<pk>"
const LIST_SPEAKERS = "api/v1/speaker/list/"
const UPDATE_SPEAKER = "api/v1/speaker/<pk>/"
const DELETE_SPEAKER = "api/v1/speaker/<pk>/"

const ADD_LOCATION = "api/v1/location/"
const GET_SPECIFIC_LOCATION = "api/v1/location/<pk>"
const LIST_LOCATIONS = "api/v1/location/list/"
const UPDATE_LOCATION = "api/v1/location/<pk>/"
const DELETE_LOCATION = "api/v1/location/<pk>/"

const ADD_ACCOMMODATION = "api/v1/accommodation/"
const GET_SPECIFIC_ACCOMMODATION = "api/v1/accommodation/<pk>"
const LIST_ACCOMODATIONS = "api/v1/accommodation/list/"
const UPDATE_ACCOMMODATION = "api/v1/accommodation/<pk>/"
const DELETE_ACCOMMODATION = "api/v1/accommodation/<pk>/"

const ADD_ROOM = "api/v1/accommodation-room/"
const GET_SPECIFIC_ROOM = "api/v1/accommodation-room/<pk>"
const LIST_ROOMS = "api/v1/accommodation-room/list/"
const UPDATE_ROOM = "api/v1/accommodation-room/<pk>/"
const DELETE_ROOM = "api/v1/accommodation-room/<pk>/"

const ADD_HAPPENING = "api/v1/happening/"
const GET_SPECIFIC_HAPPENING = "api/v1/happening/<pk>"
const LIST_HAPPENINGS = "api/v1/happening/list/"
const UPDATE_HAPPENING = "api/v1/happening/<pk>/"
const DELETE_HAPPENING = "api/v1/happening/<pk>/"

const ADD_EVENT = "api/v1/event/"
const GET_SPECIFIC_EVENT = "api/v1/event/<pk>"
const LIST_EVENTS = "api/v1/event/list/"
const UPDATE_EVENT = "api/v1/event/<pk>/"
const DELETE_EVENT = "api/v1/event/<pk>/"

const LIST_OPTIONS = "api/v1/event-option";
const LIST_CURRENCIES = "api/v1/event-currency";
const LIST_BED_SIZES = "api/v1/room-bedsize";
const LIST_COMPETITIONS = "api/v1/event-competition";
const LIST_EDITIONS = "api/v1/event-edition";
const LIST_ROLES = "api/v1/event-role";


export default {
    // EVENTS
    addEvent(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_EVENT, payload).then(response => {
            return response.data;
        });
    },
    getSpecificEvent(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_EVENT.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    listEvents(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_EVENTS, payload).then(response => {
            return response.data;
        });
    },
    updateEvent(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_EVENT.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteEvent(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_EVENT.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },

    // MAIN INFOS
    uploadFiles(payload) {
        ApiService.setFormDataHeader();
        return ApiService.post(UPLOAD_GALLERY, payload).then(response => {
            return response.data;
        });
    },
    getGallery(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_GALLERY, payload).then(response => {
            return response.data;
        });
    },
    deleteImage(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_IMAGE, payload).then(response => {
            return response.data;
        });
    },

    // HAPPENINGS
    addHappening(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_HAPPENING, payload).then(response => {
            return response.data;
        });
    },
    getSpecificHappening(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_HAPPENING.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    listHappenings(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_HAPPENINGS, payload).then(response => {
            return response.data;
        });
    },
    updateHappening(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_HAPPENING.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteHappening(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_HAPPENING.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },

    // LOCATIONS
    addLocation(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_LOCATION, payload).then(response => {
            return response.data;
        });
    },
    getSpecificLocation(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_LOCATION.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    listLocations(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_LOCATIONS, payload).then(response => {
            return response.data;
        });
    },
    updateLocation(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_LOCATION.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteLocation(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_LOCATION.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },

    // ACCOMMODATIONS
    addAccommodation(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_ACCOMMODATION, payload).then(response => {
            return response.data;
        });
    },
    getSpecificAccommodation(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_ACCOMMODATION.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    listAccommodations(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_ACCOMODATIONS, payload).then(response => {
            return response.data;
        });
    },
    updateAccommodation(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_ACCOMMODATION.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteAccommodation(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_ACCOMMODATION.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },

    // ROOMS
    addRoom(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_ROOM, payload).then(response => {
            return response.data;
        });
    },
    getSpecificRoom(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_ROOM.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    listRooms(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_ROOMS, payload).then(response => {
            return response.data;
        });
    },
    updateRoom(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_ROOM.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteRoom(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_ROOM.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },

    // SPEAKERS
    addSpeaker(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_SPEAKER, payload).then(response => {
            return response.data;
        });
    },
    getSpecificSpeaker(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_SPEAKER.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    listSpeakers(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_SPEAKERS, payload).then(response => {
            return response.data;
        });
    },
    updateSpeaker(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_SPEAKER.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteSpeaker(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_SPEAKER.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },

    // OTHER INFOS
    listOptions() {
        ApiService.setHeader();
        return ApiService.get(LIST_OPTIONS).then(response => {
            return response.data;
        });
    },
    listCurrencies() {
        ApiService.setHeader();
        return ApiService.get(LIST_CURRENCIES).then(response => {
            return response.data;
        });
    },
    listBedSizes() {
        ApiService.setHeader();
        return ApiService.get(LIST_BED_SIZES).then(response => {
            return response.data;
        });
    },
    listCompetitons() {
        ApiService.setHeader();
        return ApiService.get(LIST_COMPETITIONS).then(response => {
            return response.data;
        });
    },
    listEditions() {
        ApiService.setHeader();
        return ApiService.get(LIST_EDITIONS).then(response => {
            return response.data;
        });
    },
    listRoles() {
        ApiService.setHeader();
        return ApiService.get(LIST_ROLES).then(response => {
            return response.data;
        });
    }
};
